import Axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Button, Snackbar, Alert } from "@mui/material";
import { useState } from "react";
import FilesTable from '../../Partials/FilesTable/FilesTable';
import "./Dashboard.css";

const Dashboard = () => {
    const [showBox, setShowBox] = useState("none");

    const [snackBar, setSnackBar] = useState({
        show: false,
        severity: "success",
        text: ""
    });

    const sendFile = (e) => {
        // Recuperiamo il file dall'input
        const file = e.target.files[0];

        // Prepariamo l'oggetto da spedire al server
        const data = new FormData();
        data.append('file', file);

        Axios.post('/loadFile', data)
            .then(res => {
                if (res.data.fileUploaded) {
                    setSnackBar({ show: true, severity: "success", text: "File caricato con successo" });
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 1000)

                }
            });
    }

    useEffect(() => {
        Axios.post("/checkLogin")
            .then(res => {
                if (!res.data.allowed) {
                    window.location.href = "/areaprivata";
                } else {
                    setShowBox("block");
                }
            })
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section className="dashboard">
            <div className="background"></div>
            <div className="card">
                <div className="card-section">
                    <Container align="center" sx={{ display: showBox }}>
                        <Button component="label" sx={{ marginBottom: ".5rem" }}>Carica un nuovo documento <input id="filename" type="file" onChange={sendFile} hidden /></Button>
                        <FilesTable />
                        <Snackbar open={snackBar.show} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <Alert variant="filled" severity={snackBar.severity} onClose={() => setSnackBar({ show: false })}>
                                {snackBar.text}
                            </Alert>
                        </Snackbar>
                    </Container>
                </div>
            </div>
        </section>
    )
}

export default Dashboard;