import { Component } from "react";
import { Card, Typography } from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

class OfficeCard extends Component {
  render = () => {
    return (
      <Card variant="outlined" sx={{ padding: "2rem" }}>
          <Typography variant="h5"><WatchLaterIcon fontSize="large" /></Typography>
        <Typography variant="h5">{this.props.place}</Typography>
        <Typography>{this.props.days}</Typography>
        <Typography>{this.props.hour}</Typography>
        <Typography>{this.props.special}</Typography>
      </Card>
    );
  };
}

export default OfficeCard;
