import { Component } from 'react';
import "./Cqc.css"
import AOS from 'aos';
import { Container, Typography } from "@mui/material";
import FmdBadIcon from "@mui/icons-material/FmdBad";

import FilesTable from "../../Partials/FilesTable/FilesTable";

class Cqc extends Component {

    componentDidMount = () => {
        AOS.init({
            once: true,
        });
        AOS.init({ disable: "mobile" });
        window.scrollTo(0, 0);
    };

    render = () => {
        return (
            <section className="cqc">
                <div className="background"></div>
                <div className="card" data-aos="fade-up" data-aos-delay="200">
                    <Container>
                        <div className="card-section">
                            <Typography id="title" variant="h2" textAlign="center">
                                Corso rilascio C.Q.C
                            </Typography>
                        </div>
                        <div className="card-section">
                            <Typography id="subtitle" variant="h5">
                                <FmdBadIcon fontSize="large" />
                                Corso rinnovo C.Q.C
                            </Typography>
                            {/* <Typography>
                A febbraio 2022 inizierà un corso per il conseguimento della
                carta di qualificazione del conducente per i possessori di
                patente C CE D DE.
                Chi fosse interessato può richiedere
                maggiori informazioni o effettuare l'iscrizione al corso presso
                una delle nostre sedi. Le lezioni si tengono normalmente il
                venerdì dalle 19.00 alle 22:00 e il sabato dalle 8:00 alle 14:00
              </Typography> */}
                        </div>
                        <div className="card-section">
                            <Typography textAlign="center">
                                Presso le nostre sedi sono disponibili i calendari con le date
                                precise per i corsi di rinnovo della CQC meci e persone che
                                verranno effettuati nei prossimi mesi. Sono previsti corsi
                                intensivi, serali, solo sabato.
                                <br />I corsi vengono effettuati in collaborazione con il
                                Consorzio Scuole Guida di Cuneo nella sede del centro di
                                istruzione di San Rocco Castagnaretta. Per informazioni o
                                iscrizioni telefonare al n° 0171 65804.
                            </Typography>
                            <Typography sx={{ marginTop: ".5rem", marginBottom: ".5rem" }} textAlign="center">E' possibile visionare i prossimi calendari di rinnovo scaricando i pdf sottostanti:</Typography>
                        </div>
                        <div className="card-section">

                            <FilesTable customPage="corsi" />
                        </div>

                        <div className="card-section">
                            <Typography id="subtitle" variant="h5">
                                <FmdBadIcon fontSize="large" />
                                Corso conseguimento C.Q.C
                            </Typography>
                        </div>
                        <div className="card-section">
                            <Typography textAlign="center">
                                E' previso un corso di conseguimento della carta di qualificazione del conducente merci/persone (CQC) ogni 6 mesi
                            </Typography>
                            <Typography sx={{ marginTop: ".5rem", marginBottom: ".5rem" }} textAlign="center">E' possibile visionare i prossimi calendari del prossimo corso scaricando il pdf sottostante:</Typography>
                        </div>
                        <div className="card-section">
                            <FilesTable />
                        </div>
                    </Container>
                </div>
            </section>
        );
    }

}



export default Cqc;