import { Component } from 'react';
import { Box, Grid, Container, Typography, Link } from '@mui/material';
import ContactAccordion from './ContactAccordion';
import './Contact.css';
import AOS from 'aos';

import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PointsAccordion from "./PointsAccordion";

import FilesTable from '../../Partials/FilesTable/FilesTable';

class Contact extends Component {
    componentDidMount = () => {
        AOS.init({
            once: true,
        });
        AOS.init({ disable: "mobile" });
        window.scrollTo(0, 0);
    }

    render = () => {
        return (
            <section className="contact">
                <div className="background"></div>
                <div className="card" data-aos="fade-up" data-aos-delay="200">
                    <Container>
                        <div className="card-section">
                            <Typography textAlign="center" id="title" variant="h2">Contatti</Typography>
                        </div>
                        <div>
                            <ContactAccordion
                                open="true"
                                place="Cuneo"
                                title="Nuova Autoscuola Europa Snc di Ferrero Paolo, Giordana Valerio, Grosso Danilo, Satta Remo"
                                address="Via Asilo, 5"
                                phone="0171-65804"
                                email="info@autoscuolaeuropa.eu"
                                maps="https://goo.gl/maps/UziZE4iQiD95o2XZ8"
                            />
                            <ContactAccordion
                                place="Boves"
                                title=""
                                address="Via Castello di Godego 1"
                                phone="0171-380442"
                                email="info@autoscuolaeuropa.eu"
                                maps="https://goo.gl/maps/aHcewRh9NEKw7vrP7"
                            />
                            <ContactAccordion
                                place="Busca"
                                title=""
                                address="Corso Giovanni XXIII 14"
                                phone="0171-945145"
                                email="info@autoscuolaeuropa.eu"
                                maps="https://goo.gl/maps/UnyESNTsxCLA8vNDA"
                            />
                            <ContactAccordion
                                place="Centallo"
                                title=""
                                address="Via Roata Chiusani, 27"
                                phone="0171-211515"
                                email="info@autoscuolaeuropa.eu"
                                maps="https://goo.gl/maps/Ndtzqayx5npEKjkm6"
                            />
                            <ContactAccordion
                                place="Peveragno"
                                title=""
                                address="Via Divisione Cuneense 37"
                                phone="0171-339877"
                                email="info@autoscuolaeuropa.eu"
                                maps="https://goo.gl/maps/XqbFzVixJmLFsyjA7"
                            />

                            <ContactAccordion
                                place="Morozzo"
                                title=""
                                address="Via Marconi 30"
                                phone="0171-772472"
                                email="info@autoscuolaeuropa.eu"
                                maps="https://goo.gl/maps/NsSteqrycP2zVowr6"
                            />
                        </div>
                        <div className="card-section">
                            <Typography id="subtitle" variant="h5"><EmojiPeopleIcon fontSize="large" />Contattaci</Typography>
                            <Box textAlign="center" sx={{marginBottom: "2rem"}}>
                                <Grid container spacing={2} align="center">
                                    <Grid item xs={12} md={6}>
                                        <Link href="tel:555-555-5555" underline="none">
                                            <Typography variant="h3"><LocalPhoneIcon fontSize="1rem" color="primary" /></Typography>
                                            <Typography variant="h5">TELEFONO</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Link href="mailto:info@autoscuolaeuropa.eu" underline="none">
                                            <Typography variant="h3"><MailIcon fontSize="1rem" color="primary" /></Typography>
                                            <Typography variant="h5">EMAIL</Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                            <PointsAccordion title="Richiesta Informazioni"/>
                        </div>
                        <div className="card-section">
                            <FilesTable title="Potrebbe anche esserti utile" />
                        </div>
                    </Container>
                </div>
            </section>
        )
    }
}

export default Contact;