import Axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Button, Snackbar, Alert } from "@mui/material";
import { useState } from "react";
import FilesTable from '../../Partials/FilesTable/FilesTable';
import "./Dashimage.css";

const DashImage = () => {
    const [showBox, setShowBox] = useState("none");

    const [snackBar, setSnackBar] = useState({
        show: false,
        severity: "success",
        text: ""
    });


    return (
        <section className="dashboardimage">
            <div className="background"></div>
            <div className="card">
                <div className="card-section">
                    <Container align="center" sx={{ display: showBox }}>
                        {/* <Button component="label" sx={{ marginBottom: ".5rem" }}>Carica un'immagine <input id="filename" type="file" onChange={sendFile} hidden /></Button> */}
                        <Button component="label" sx={{ marginBottom: ".5rem" }}>Carica un'immagine <input id="filename" type="file" hidden /></Button>
                        <FilesTable />
                        <Snackbar open={snackBar.show} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <Alert variant="filled" severity={snackBar.severity} onClose={() => setSnackBar({ show: false })}>
                                {snackBar.text}
                            </Alert>
                        </Snackbar>
                    </Container>
                </div>
            </div>
        </section>
    )
}

export default DashImage;