import Axios from 'axios';
import { Box, Grid, Container, Typography } from "@mui/material";
import Form from "./Form/Form";
import LockIcon from '@mui/icons-material/Lock';
import { useEffect } from "react";

const Login = () => {
    useEffect(() => {
        Axios.post("/checkLogin")
            .then(res => {
                if (res.data.allowed) {
                    window.location.href = "/dashboard";
                }
            })
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "2rem", marginRight: "2rem", height: "100vh", alignItems: "center", justifyContent: "center" }}>
            <Grid container sx={{ width: { xs: "100%", md: "50%", lg: "30%" }, background: "white", paddingTop: "2rem", paddingBottom: "2rem", borderRadius: "1rem" }}>
                <Grid item xs={12} sx={{ alignSelf: "center" }}>
                    <Typography variant="h2" align="center"><LockIcon sx={{ fontSize: "3rem" }} /></Typography>
                    <Typography variant="h5" align="center">Area Privata</Typography>
                    <Typography color="primary" align="center">Inserisci le credenziali di amministratore per accedere all'area privata</Typography>
                    <Container sx={{ width: "100%" }}>
                        <Form />
                    </Container>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Login;