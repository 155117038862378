import { Component } from 'react';
import "./Privacy.css";
import AOS from "aos";
import { Container, Typography } from '@mui/material';


class Privacy extends Component {

    componentDidMount = () => {
        AOS.init({
            once: true,
        });
        AOS.init({ disable: "mobile" });
        window.scrollTo(0, 0);
    };

    render = () => {
        return (
            <section className="privacy">
                <div className="background"></div>
                <div className="card" data-aos="fade-up" data-aos-delay="200">
                    <Container>
                        <div
                            className="card-section"
                            data-aos="fade-up"
                            data-aos-delay="600">
                            <Typography textAlign="center" id="title" variant="h2">
                                Informativa Protezione Privacy
                            </Typography>
                            <Typography id="subtitle" variant="h5">
                                Protezione Privacy
                            </Typography>
                            <p>
                                Informativa ai sensi dell'art. 13 del decreto legislativo 30 giugno 2003 n. 196, recante il Codice in materia di protezione dei dati personali.
                            </p>
                            <br />
                            <br />
                            <p>
                                Gentile Cliente,
                                La informiamo, ai sensi dell'art. 13 del D. Lgs. 196/2003 che procederemo al trattamento dei dati da Lei forniti nel rispetto della normativa in materia di tutela del trattamento dei dati personali.
                                Il conferimento dei dati e' facoltativo ma un eventuale rifiuto di fornirli potrebbe determinare l'impossibilita' di usufruire di alcuni servizi del portale.
                                I dati personali da Lei forniti sono raccolti con modalita' telematiche e trattati, anche con l'ausilio di mezzi elettronici, direttamente e/o tramite terzi delegati per le seguenti finalità:
                                <ul>
                                    <li> finalita' connesse all'esecuzione dei servizi del portale;</li>
                                    <li> invio di informazioni e novita' sulla nostra attivita';</li>
                                    <li> finalita' statistiche, rilevazione del grado di soddisfazione della Clientela.</li>
                                    <li> adempimento degli obblighi previsti dalla legge nonche' da disposizioni impartite dalle autorita' o dagli organi di vigilanza e/o controllo.</li>
                                </ul>

                                In ogni caso i suoi dati non verranno comunicati o venduti a terzi.
                                <br />
                                <br />
                                Il titolare di questo sito dichiara sotto la propria responsabilita', che il messaggio informativo del sito e' diramato
                                nel rispetto delle norme vigenti ed in conformita' alle direttive del codice deontologico ed ha lo scopo di informare il pubblico in ordine
                                alle prestazioni professionali erogate, senza utilizzare criteri visivi e simbolici propri della pubblicita' commerciale e senza effettuare
                                alcun tipo di promozione pubblicitaria con banner, links, o altri strumenti Internet; i link presenti sono di rimando a siti istituzionali o di informazione generale.
                            </p>
                        </div>
                        <div
                            className="card-section"
                            data-aos="fade-up"
                        >
                            <Typography id="subtitle" variant="h5">
                                Utilizzo della posta elettronica nei rapporti con i clienti
                            </Typography>
                            <Typography>
                                La posta elettronica nei rapporti con i clienti e con i colleghi viene utilizzata conformemente al codice
                                deontologico e secondo le norme sulla Privacy (D. Lgs. 30 giugno 2003, n. 196 - Codice in materia di protezione
                                dei dati personali e successivi provvedimenti).
                            </Typography>
                        </div>
                        <div
                            className="card-section"
                            data-aos="fade-up"
                        >
                            <Typography id="subtitle" variant="h5">
                                Uso dei cookie e sicurezza
                            </Typography>
                            <Typography>
                                Al fine di migliorare le funzionalita' del nostro portale, utilizziamo "cookie" per conservare traccia del Vostro passaggio. Un cookie consiste in un ridotto insieme di dati
                                trasferiti al vostro browser da un server Web.
                                Non si tratta di codice eseguibile e non trasmette virus!
                                La quasi totalita' dei browser e' inizialmente programmata per accettare i cookie. Potete far si' che il browser vi avverta quando ricevete un cookie, permettendovi di scegliere se accettarlo o meno
                                (per alcune pagine Web che richiedono un'autorizzazione i cookie non sono facoltativi. Gli utenti che sceglieranno di non ricevere cookie non potranno probabilmente accedere a quelle pagine).
                            </Typography>
                        </div>
                        <div
                            className="card-section"
                            data-aos="fade-up"
                        >
                            <Typography id="subtitle" variant="h5">
                            Diritti dell'Interessato
                            </Typography>
                            <Typography>
                            L'interessato potra' in ogni momento esercitare i diritti di cui all'art.7 del Decreto legislativo 30 giugno 2003, n. 196, tra i quali richiedere la modifica, l'integrazione, l'aggiornamento, la conferma o la cancellazione dei dati conservati. Tali diritti potranno esercitarsi attraverso le seguenti modalità: 
                            <ul>
                                <li> invio di un e-mail</li>
                                <li> invio un di fax</li>
                                <li> invio di una raccomandata A/R agli indirizzi presenti sul nostro portale</li>
                            </ul>
                            Il responsabile del trattamento dei dati personali, ai sensi dell'art. 7, e' l'Amministratore pro tempore.
                            <br />
                            <br />
                            Per ulteriori informazioni o aggiornamenti visitare il sito del <a href="https://www.garanteprivacy.it/">Garante per la Privacy</a> 
                            </Typography>
                        </div>
                    </Container>

                </div >
            </section >
        );
    };

}

export default Privacy;