import { Component } from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';

class CarCard extends Component {
    render = () => {
        return (
            <Card sx={{marginBottom:"2rem"}}>
                <CardMedia component="img" image={this.props.image} height="200"></CardMedia>
                <CardContent>
                    <Typography variant="h5" borderBottom={`2px solid ${this.props.color}`}>
                        {this.props.title}
                    </Typography>
                    <span style={{marginTop:"0.35rem"}}>{this.props.license}</span>
                </CardContent>
            </Card>
        )
    }
}

export default CarCard;