import { Component } from "react";
import "./LegalNotice.css";
import AOS from "aos";
import { Container, Typography } from "@mui/material";

class LegalNotice extends Component {
  componentDidMount = () => {
    AOS.init({
      once: true,
    });
    AOS.init({ disable: "mobile" });
    window.scrollTo(0, 0);
  };

  render = () => {
    return (
      <section className="legalNotice">
        <div className="background"></div>
        <div className="card">
          <Container>
            <div className="card-section">
              <Typography textAlign="center" id="title" variant="h2">
                Note Legali
              </Typography>
              <Typography id="subtitle" variant="h5">
                Termini e condizioni di utilizzo
              </Typography>
              <p>
                Conformità del Sito Internet al Codice Deontologico Il titolare
                di questo sito dichiara sotto la propria responsabilità, che il
                messaggio informativo del sito è diramato nel rispetto delle
                norme vigenti ed in conformità alle direttive del codice
                deontologico ed ha lo scopo di informare il pubblico in ordine
                alle prestazioni professionali erogate, senza utilizzare criteri
                visivi e simbolici propri della pubblicità commerciale e senza
                effettuare alcun tipo di promozione pubblicitaria con banner,
                links, o altri strumenti Internet; i link presenti sono di
                rimando a siti istituzionali o di informazione generale.{" "}
              </p>
            </div>
            <div className="card-section">
              <Typography id="subtitle" variant="h5">
                Utilizzo della posta elettronica nei rapporti con i clienti
              </Typography>
              <Typography>
                La posta elettronica nei rapporti con i clienti e con i colleghi
                viene utilizzata conformemente al codice deontologico e secondo
                le norme sulla Privacy (D. Lgs. 30 giugno 2003, n. 196 - Codice
                in materia di protezione dei dati personali e successivi
                provvedimenti).
              </Typography>
            </div>
            <div className="card-section">
              <Typography id="subtitle" variant="h5">
                Proprietà intellettuale
              </Typography>
              <Typography>
                Il template ed il layout grafico sono soggetti a Copyright per
                cui è vietata ogni forma di riproduzione, anche parziale. I
                contenuti del Sito non possono, né totalmente né in parte,
                essere distribuiti, copiati, riprodotti o pubblicati. Gli
                eventuali marchi e i loghi che compaiono su questo sito sono dei
                legittimi proprietari.
              </Typography>
            </div>
            <div className="card-section">
              <Typography id="subtitle" variant="h5">
                Link ad altri siti
              </Typography>
              <Typography>
                Non controllando i contenuti e la funzionalità degli eventuali
                siti web collegati al nostro sito, non siamo responsabili per
                danni diretti o indiretti derivanti dalla loro consultazione.
              </Typography>
            </div>
            <div className="card-section">
              <Typography id="subtitle" variant="h5">
                Limiti di responsabilità
              </Typography>
              <Typography>
                Le informazioni e i dati pubblicati in questo sito hanno
                esclusivo scopo informativo e non rivestono carattere di
                ufficialità, pertanto non possiamo garantire né esplicitamente
                né implicitamente l'esattezza, la completezza e l'affidabilità
                delle informazioni fornite.
              </Typography>
            </div>
            <div className="card-section">
              <Typography id="subtitle" variant="h5">
                Legge e giurisdizione
              </Typography>
              <Typography>
                Le presenti note legali e di copyright sono regolate dalla legge
                italiana. Per qualsiasi controversia sarà esclusivamente
                competente il Tribunale della circoscrizione nella quale risiede
                il responsabile del sito.
              </Typography>
            </div>
          </Container>
        </div>
      </section>
    );
  };
}

export default LegalNotice;
