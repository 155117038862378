import { Component } from 'react';
import { Typography } from '@mui/material';

class Footer extends Component {
    render = () => {
        return (
            <footer>

                <Typography sx={{ marginBottom: ".2rem" }} variant="h5"> Nuova Autoscuola Europa Snc di Ferrero Paolo, Giordana Valerio, Grosso Danilo, Satta Remo - Via Asilo, 5 Cuneo (CN) - P.IVA 00214560047 -
                    <a href='/doc/privacy.pdf' target="_blank"> Privacy - </a>
                    <br />
                    <a style={{ textDecoration: "underline" }} href="/areaprivata">Area Privata</a>
                    <Typography sx={{fontSize:".8rem", marginTop: "5px"}}>Creato da Mattia Devigus e Andrea Gondolo</Typography>
                </Typography>
            </footer>
        )
    }
}

export default Footer;
