import { Component, useState } from "react";
import {
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    Button,
    Modal,
    Link,
} from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import SchoolDriveModal from "./SchoolDriveModal";

class OfficeCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalCN: false,
            modalBV: false,
            modalBU: false,
            modalCE: false,
            modalPE: false,
            modalMO: false,
        };
    }

    handleOpen = (e) => {
        this.setState({ [e.target.name]: true });
    };
    closeOperation = (e) => {
        this.setState({
            modalCN: false,
            modalBV: false,
            modalBU: false,
            modalCE: false,
            modalPE: false,
            modalMO: false,
        });
    };

    render = () => {
        return (
            <Card sx={{ paddingBottom: "1rem", height: "100%" }}>
                <CardMedia
                    component="img"
                    image={this.props.image}
                    height="125"
                ></CardMedia>
                <CardContent>
                    <Typography variant="h5"></Typography>
                    <Typography variant="h5">{this.props.place}</Typography>
                    <Typography>{this.props.address}</Typography>
                    <Typography>{this.props.days}</Typography>
                    <Typography>{this.props.hour}</Typography>
                    <Typography>{this.props.testDay}</Typography>
                    <Typography>{this.props.phone}</Typography>
                </CardContent>
                <CardActions>
                    <Button
                        size="small"
                        onClick={() => {
                            window.open(this.props.maps, "_blank");
                        }}
                    >
                        Apri Maps
                    </Button>
                    <Button
                        size="small"
                        name={this.props.modalName}
                        onClick={this.handleOpen}
                    >
                        Mostra foto
                    </Button>
                    <SchoolDriveModal
                        modalName={this.props.modalName}
                        show={this.state[this.props.modalName]}
                        onClose={this.closeOperation}
                    />
                </CardActions>
            </Card>
            /* <Link target="_blank" href={this.props.maps} underline="none" style={{ cursor: "pointer" }}> */
            /* </Link> */
        );
    };
}

export default OfficeCard;
