import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Paper,
    Snackbar,
    Alert,
    CircularProgress,
    Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Axios from 'axios';
import { useEffect } from "react";
import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const FilesTable = (props) => {
    const [rows, setRows] = useState([]);
    const [fileToDelete, setFileToDelete] = useState("");
    const [snackBar, setSnackBar] = useState({
        show: false,
        severity: "",
        text: ""
    });
    const [snackBarFile, setSnackBarFile] = useState({
        show: false,
        severity: "success",
        text: ""
    });
    const [showLoader, setShowLoader] = useState(true);
    const [showBox, setShowBox] = useState("none");
    const [showTitle, setShowTitle] = useState("block");

    useEffect(() => {
        console.log(props.customPage);
        const page = props.customPage !== undefined ? props.customPage : window.location.pathname.replace("/", "");
        if (props.title === undefined) setShowTitle("none");
        Axios.get('/getFileList', { params: { page: page } })
            .then(res => {
                const files = res.data;
                let arr = [];
                for (let file of files) {
                    arr.push({ id: file._id, name: file.name, path: file.path, hash: file.hash, date: file.date, page: file.page || "" });
                }

                if (arr.length > 0) setShowBox("block");

                setRows(arr);
                setShowLoader(false);
            })
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const getFileFromHash = (hash, fileName) => {
        Axios.get('/getfilefromhash', { params: { hash: hash }, responseType: 'blob' })
            .then(res => {
                const fileURL = window.URL.createObjectURL(new Blob([res.data]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                fileLink.setAttribute('target', '_blank');
                document.body.appendChild(fileLink);
                fileLink.click();
                fileLink.remove();
            });
    }

    const confirmDelete = (hash) => {
        setFileToDelete(hash);
        setSnackBar({ show: true });
    }

    const deleteFileFromHash = () => {
        const hash = fileToDelete;
        Axios.post('/deletefilefromhash', { hash: hash })
            .then(res => {
                if (res.data.deleted) {
                    window.location.reload(true);
                };
            });
    }

    const getFormattedDate = (dateText) => {
        const date = new Date(dateText);
        return "Caricato: " + date.toLocaleDateString("it-IT", {
            day: "numeric",
            month: "long",
            year: "numeric"
        }) + " " + date.toLocaleTimeString("it-IT");
    }

    const resetFileToDelete = () => {
        setFileToDelete("");
        setSnackBar({ show: false });
    }

    const setPageFile = (e, index) => {
        const files = rows;
        files[index].page = e.target.value;

        const data = {
            hash: files[index].hash,
            page: files[index].page
        }

        setRows(Object.values(files));
        Axios.post('setpagefile', data)
            .then(res => {
                if (res.data.updated) {
                    setSnackBarFile({
                        show: true,
                        severity: "success",
                        text: "File aggiornato"
                    });
                }
            })
    }

    return (
        <Box sx={{ display: showBox }}>
            <Typography sx={{ display: showTitle }} id="subtitle" variant="h5"><PictureAsPdfIcon fontSize="large" /> {props.title}</Typography>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ padding: "8px" }}>Ultimi documenti</StyledTableCell>
                            <StyledTableCell sx={{ padding: "8px" }} align="right"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    {row.name.includes(".pdf") ? <h3><PictureAsPdfIcon fontSize="large" sx={{ marginRight: ".2rem", color: "red" }} />{row.name}</h3> : <h3>{row.name}</h3>}
                                    <p>{getFormattedDate(row.date)}</p>
                                </StyledTableCell>

                                <StyledTableCell component="td" scope="row" align="right">
                                    {window.location.pathname === "/dashboard" &&
                                        <FormControl size="small" align="left" sx={{ display: { xs: 'none', md: 'inline-flex', minWidth: 120 } }}>
                                            <InputLabel id="labelSelect">Pagina</InputLabel>
                                            <Select id={"select" + row.hash} labelId="labelSelect" label="Pagina" hash={row.hash} value={row.page} onChange={(e) => { setPageFile(e, i) }}>
                                                <MenuItem value="">
                                                    <em>Nessuna Pagina</em>
                                                </MenuItem>
                                                <MenuItem value='home'>Home</MenuItem>
                                                <MenuItem value='autoscuola'>Autoscuola</MenuItem>
                                                <MenuItem value='patente'>Patente</MenuItem>
                                                <MenuItem value='corsi'>Cqc 1</MenuItem>
                                                <MenuItem value='cqc'>Cqc 2</MenuItem>
                                                <MenuItem value='rinnovo_patenti'>Rinnovo Patenti</MenuItem>
                                                <MenuItem value='punti'>Punti</MenuItem>
                                                <MenuItem value='contatti'>Contatti</MenuItem>
                                            </Select>
                                        </FormControl>}
                                    <Button id={row.id} onClick={() => { getFileFromHash(row.hash, row.name) }} ><DownloadIcon /></Button>
                                    {window.location.pathname === "/dashboard" && <Button onClick={() => { confirmDelete(row.hash) }}><DeleteIcon /></Button>}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <Snackbar autoHideDuration={2000} open={snackBar.show} message="Stai per eliminare il file" anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => setSnackBar({ show: false })} action={
                    <div>
                        <Button color="error" size="small" onClick={() => { resetFileToDelete() }}>
                            Annulla
                        </Button>
                        <Button color="inherit" size="small" onClick={() => { deleteFileFromHash() }}>
                            Conferma
                        </Button>
                    </div>
                }>
                </Snackbar>
                <Snackbar autoHideDuration={2000} open={snackBarFile.show} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => setSnackBarFile({ show: false })}>
                    <Alert variant="filled" severity={snackBarFile.severity} onClose={() => setSnackBarFile({ show: false })}>
                        {snackBarFile.text}
                    </Alert>
                </Snackbar>
            </TableContainer>
            {showLoader && <CircularProgress />}
        </Box>
    )
}

export default FilesTable;